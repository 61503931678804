// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, CssBaseline, Stack } from '@mui/material';
import { Outlet } from "react-router-dom";
import { Container } from '@mui/system';
import bsp_logo from './../images/bsp_logonew.png';
import pci_dss_sticker from './../images/pci_dss_sticker.png';
import poweredby from './../images/poweredby.png';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            // marginLeft: -(drawerWidth - 20),
            // width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            // width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            // width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const MainUi = () => {
    const theme = useTheme;

    return (
        <Box sx= {{ display: 'flex' }}>
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Main
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Card>
                    <CardContent sx={{ marginTop: '5px', padding: '30px'}}>
                        <Outlet />
                        <Stack
                            direction="column"
                            sx={{
                                marginTop: '50px',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                src={poweredby}
                                width= "220px"
                            />
                            <Stack direction="row" spacing={1}>
                                <img
                                    src={bsp_logo}
                                    width= "40px"
                                    height= "40px"
                                />
                                <img
                                    src={pci_dss_sticker}
                                    width= "80px"
                                    height= "40px"
                                />
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Main>
            </Container>
        </Box>
    )
};

export default MainUi;

