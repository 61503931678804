import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import reducer from './reducer';
import thunk from 'redux-thunk';

// ==============================|| REDUX - MAIN STORE ||============================== //

const middleWares = [thunk];
const initialState = {};

const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleWares))
);

const persister = 'Fastek';

export { store, persister };