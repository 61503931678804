//action
import { INITIAL_BEGIN } from "./actions";

export const initialState = {
    beginInitialization: false,
}

// ============================== || INITIAL REDUCER || ============================== //

const initialReducer = (state = initialState, action) => {
    switch(action.type) {
        case INITIAL_BEGIN:
            return {
                ...state,
                beginInitialization: action.beginInitialization
            };
        default:
            return state;
    }
};

export default initialReducer;