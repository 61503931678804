import logo from './logo.svg';
import './App.css';
import { useSelector } from 'react-redux';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';


function App() {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <Routes />
    </StyledEngineProvider>
  );
}

export default App;
