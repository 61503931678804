import { lazy } from "react";
import MainUi from './../ui-fragments/MainUi';
import Loadable from "../ui-component/Loadable";

const OtpUi = Loadable(lazy(() => import('./../ui-fragments/OtpUi')));
const TryAgainUi = Loadable(lazy(() => import('./../ui-fragments/TryAgain')));
const FailedUi = Loadable(lazy(() => import('./../ui-fragments/FailedUi')));
const SuccessUi = Loadable(lazy(() => import('./../ui-fragments/SuccessUi')));
const ProcessingUi = Loadable(lazy(() => import('./../ui-fragments/ProcessingUi')));
const AccountsUi = Loadable(lazy(() => import('./../ui-fragments/AccountUi')));

const MainRoutes = {
    path: '/',
    element: [
        <MainUi key={Math.random()} />
    ],
    children: [
        {
            path: '/otp/:type',
            element: [
                <OtpUi key={Math.random()} />
            ]
        },
        {
            path: '/tryagain',
            element: [
                <TryAgainUi key={Math.random()} />
            ]
        },
        {
            path: '/failed',
            element: [
                <FailedUi key={Math.random()} />
            ]
        },
        {
            path: '/success',
            element: [
                <SuccessUi key={Math.random()} />
            ]
        },
        {
            path: '/processing',
            element: [
                <ProcessingUi key={Math.random()} />
            ]
        },
        {
            path: '/accounts/:type',
            element: [
                <AccountsUi key={Math.random}/>
            ]
        }
    ]
};

export default MainRoutes;